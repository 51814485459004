@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* font-family: 'Megrim', cursive; */


 * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: #000;
  color: #B6B6B6;
  font-weight: 500;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;

}

.App {
  height: 100vh;
  position: relative;
  
}
